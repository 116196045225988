<template>
  <Card class="file">
    <section class="tools">
      <div class="title">
        <h1>学习工具</h1>
      </div>
      <ul>
        <li @click="handleNavWord">
          <img src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/0ce85efb90446385c294069920904d31cbfed449321d2ae288b42d516ca708d2.png"
               alt="">
          <div>
            <h2>背单词</h2>
            <p>PTE考试必备分类词汇</p>
          </div>
          <a > <i class="iconfont icon-class-right"></i> </a>
        </li>
        <li style="background-color: #fef6f4" @click="handleNavExam">
          <img src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/0275d534d01415bb0bc2a956b3efdeac4c3ce896747e5d3bc5b9cc530667fbe1.png"
               alt="">
          <div>
            <h2>仿真模考</h2>
            <p>亲身感受临场考试</p>
          </div>
          <a > <i class="iconfont icon-class-right"></i> </a>
        </li>
        <li style="background-color: #f5faf5" @click="handleRadioTip">
          <img src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/6d439dd81aed5cd01b532d918451f6d0cc5931e9d930ad2fca4538d43d036c84.png"
               alt="">
          <div>
            <h2>PTE真题随身听 <img
                    src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/admin_file_1618803628722635161920.png"
                    alt=""></h2>
            <p>PTE真题音频循环洗脑播放</p>
          </div>
          <a > <i class="iconfont icon-class-right"></i> </a>
        </li>
      </ul>
    </section>
    <section class="download">
      <div class="title">
        <h1>资料下载</h1>
        <a @click="handleNavFile">全部 <i class="iconfont icon-Group64"/> </a>
      </div>
      <ul>
        <li v-for="item of list" :key="item.id">
          <img src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/15c862ab6881aac6e19c58bd208035834f56835ec1a09a417f37ccf4183f8f3e.png"
               alt="">
          <div><h3>{{item.title}}</h3></div>
          <span v-if="item.is_new_flag == 1">NEW</span>
        </li>
      </ul>
    </section>

    <RadioDialog ref="radioDialog" />
  </Card>
</template>

<script>
  import Card from '@/components/card'
  import RadioDialog from './radioDialog'

  export default {
    components: {
      Card,
      RadioDialog
    },
    props: {
      list: {
        type: Array,
        default: () => []
      }
    },
    methods: {
      handleNavFile() {
        this.$router.push({ name: 'FileDownload' })
      },
      handleNavWord() {
        this.$router.push({
          name: 'wordBook'
        })
      },
      handleNavExam() {
        this.$router.push({
          name: 'examList'
        })
      },
      handleRadioTip() {
        // this.$refs.radioDialog.open()
        this.$router.push({
          name: 'Walkman',
          query: {
            clid: '35'
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .file {
    flex: 1;
    display: flex;

    .title {
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 18px;
        padding-bottom: 16px;
      }

      a {
        font-size: 16px;
        color: #1F1F40;
        cursor: pointer;
        text-decoration: none;

        i {
          display: inline-block;
          transform: rotateZ(90deg);
        }
      }
    }

    section {
      flex: 1;
    }

    .tools {
      border-right: 1px solid #D5D5D5;
      display: flex;
      flex-direction: column;
      padding-right: 30px;

      ul {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        li {
          display: flex;
          align-items: center;
          padding: 10px;
          background-color: #f2f8fc;
          border-radius: 10px;
          cursor: pointer;

          > img {
            width: 54px;
          }

          > div {
            flex: 1;
            padding: 0 14px;
            color: #9C9C9C;
            font-size: 14px;

            h2 {
              font-size: 16px;
              color: #222D3D;
              padding-bottom: 8px;

              > img {
                height: 18px;
                transform: translateY(3px);
              }
            }
          }

          > a {
            text-decoration: none;
            display: inline-block;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
              color: #E6534C;
            }
          }
        }
      }
    }

    .download {
      padding-left: 30px;

      ul {
        li {
          display: flex;
          align-items: center;
          padding: 14px 0;
          border-top: 1px solid #EBEBF2;

          &:last-child {
            border-bottom: 1px solid #EBEBF2;
          }

          img {
            width: 12px;
          }

          div {
            flex: 1;
            width: 0;

            h3 {
              font-size: 16px;
              font-weight: normal;
              padding: 0 10px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

          }

          span {
            display: inline-block;
            color: #AD1625;
            background-color: #FFD2DD;
            border-radius: 10px;
            padding: 4px 8px;
          }
        }
      }
    }
  }
</style>
