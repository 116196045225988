import { render, staticRenderFns } from "./userStat.vue?vue&type=template&id=97af0596&scoped=true"
import script from "./userStat.vue?vue&type=script&lang=js"
export * from "./userStat.vue?vue&type=script&lang=js"
import style0 from "./userStat.vue?vue&type=style&index=0&id=97af0596&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97af0596",
  null
  
)

export default component.exports