<template>
  <el-dialog
          class="radio-dialog"
          :visible.sync="visible"
          width="500px"
          :append-to-body="true"
  >
    <section class="title">
      <h1>5分钟测试</h1>
      <p>让你在PTEGO的练习更有效率</p>
    </section>
    <section class="info">
      <div>
        <h3>测试结束你可以获得</h3>
        <ul>
          <li>量身定制的PTE专属学习计划</li>
          <li>更详尽的一对一口语测试及反馈</li>
        </ul>

        <div class="qrcode">
          <img :src="require('@/assets/temporary/download.png')" alt="">
          <p>扫码下载PTEGO APP立刻测试</p>
        </div>
      </div>
    </section>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        visible: false
      }
    },
    methods: {
      open() {
        this.visible = true
      }
    }
  }
</script>

<style scoped lang="scss">
  .radio-dialog {
    ::v-deep .el-dialog {
      border-radius: 10px;
      overflow: hidden;
    }

    ::v-deep .el-dialog__header {
      padding: 0;
    }

    ::v-deep .el-dialog__body {
      padding: 0 !important;
    }

    ::v-deep .el-icon-close {
      color: #9dc2ff;
      font-size: 22px;
      font-weight: bold;
    }

    .title {
      background-color: #3b86ff;
      text-align: center;
      padding: 40px 0 25px;
      color: #9dc2ff;
      font-size: 24px;

      h1 {
        color: #fff;
        font-size: 32px;
        font-weight: normal;
      }
    }

    .info {
      padding: 34px;
      display: flex;
      justify-content: center;

      h3 {
        font-size: 16px;
        color: #9C9C9C;
        padding-bottom: 8px;
        font-weight: normal;
      }
      ul {
        color: #3A3A3A;
        font-size: 18px;
        > li {
          margin-top: 10px;
          padding-left: 25px;
          position: relative;
          &:after {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #3B86FF;
            left: 0;
            top: 8px;
          }
        }
      }
      .qrcode {
        margin-top: 26px;
        text-align: center;
        img {
          width: 95px;
          height: 95px;
        }
        p {
          font-size: 12px;
          color: #8e8e9f;
          margin-top: 6px;
        }
      }

    }
  }
</style>
