<template>
  <Card class="user-stats">
    <section class="user-info">
      <h1 class="name">Hi {{ info.user_name }}，</h1>
      <p>{{$t('message.Home.title')}}</p>
    </section>
    <section>
      <ol>
        <li>
          <h2>{{$t('message.Home.userStat')}}</h2>
          <p><strong>{{info.goal_score || '--'}}</strong></p>
        </li>
        <li>
          <h2>{{$t('message.Home.distance')}} <i class="iconfont icon-bianji" @click="showDialog = true"/></h2>
          <p><strong>{{info.exam_last_day || '--'}}</strong>{{$t('message.Home.day')}}</p>
        </li>
        <li>
          <h2>{{$t('message.Home.today')}}</h2>
          <p><strong>{{info.try_today_count || '--'}}</strong>{{$t('message.Home.topic')}}</p>
        </li>
      </ol>
    </section>
    <section>
      <p class="total">
        <span>{{$t('message.Home.totalText')}}{{info.gre_try_count}}{{$t('message.Home.topic')}}</span>
        <span class="rate">({{ info.gre_try_rate }}%{{$t('message.Home.topicLibrary')}})</span>
      </p>
      <el-progress
              :percentage="info.gre_try_rate"
              :color="'#FFC06A'"
              :show-text="false"
              :stroke-linecap="'square'"
              :stroke-width="8"
      />
    </section>
    <footer>
      <a @click="handleOpenStat">{{$t('message.Home.navStudyData')}} <i class="iconfont icon-Group64"/> </a>
    </footer>

    <SettingDialog
            v-if="showDialog"
            :visible="showDialog"
            @closeDialog="closeDialog"
            @successDialog="onChange"
    />
    <StatDialog ref="statDialog" :info="allStat" />
  </Card>
</template>

<script>
  import Card from '@/components/card'
  import SettingDialog from './settingDialog'
  import StatDialog from './statDialog'

  export default {
    components: {
      SettingDialog,
      StatDialog,
      Card,
    },
    props: {
      info: {
        type: Object,
        default: () => ({})
      },
      allStat: {
        type: Object,
        default: () => ({})
      },
      onChange: {
        type: Function,
        default: () => {
        }
      }
    },
    data() {
      return {
        showDialog: false
      }
    },
    methods: {
      closeDialog() {
        this.showDialog = false
      },
      handleOpenStat() {
        this.$refs.statDialog.open()
      }
    }
  }
</script>

<style scoped lang="scss">
  .user-stats {
    width: 300px;
    min-height: 300px;
    color: #1F1F40;

    section {
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }

    .user-info {
      border-bottom: 1px solid #D5D5D5;
      padding-bottom: 20px;

      .name {
        font-size: 18px;
        font-weight: bold;
        line-height: 2;
      }
    }

    ol {
      display: flex;
      justify-content: space-between;
      padding-top: 6px;

      > li {
        text-align: center;

        h2 {
          font-size: 16px;
          font-weight: normal;
          position: relative;

          &:after {
            content: '';
            width: 14px;
            height: 2px;
            background-color: #FF606D;
            border-radius: 2px;
            position: absolute;
            left: 50%;
            bottom: -3px;
            transform: translateX(-50%);
          }

          > i {
            color: #9C9C9C;
            cursor: pointer;
          }
        }

        p {
          font-size: 12px;
          margin-top: 12px;

          strong {
            font-size: 30px;
            margin-right: 4px;
          }

          &:first-child {
            strong {
              margin-right: 0;
            }
          }
        }
      }
    }

    .total {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      padding-top: 10px;
      font-size: 16px;

      .rate {
        color: #9C9C9C;
      }
    }

    footer {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 30px;
      font-size: 16px;

      a {
        cursor: pointer;
        text-decoration: none;
        color: #9C9C9C;

        i {
          position: relative;
          display: inline-block;
          transform: rotateZ(90deg);
        }
      }
    }
  }
</style>
