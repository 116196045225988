<template>
  <Card class="gre-stat">
    <div class="gre-title">
      <h3>{{ i18n.Home.MachineUpdate }}</h3>
      <time>{{info.gre_update_date}}{{$t('message.Home.updated')}}</time>
    </div>
    <div class="item">
      <div class="item-text">
        <div class="title">{{ i18n.Home.Totalbanks }}</div>
        <div class="num">
          {{ info.gre_total }}
          <span><i class="f-icon icon-Up" />{{ info.gre_total_add }}</span>
        </div>
      </div>
      <v-chart class="gre-stat-chart" :options="getOptions(gre_total_map)" />
    </div>
    <div class="item">
      <div class="item-text">
        <div class="title">{{ i18n.Home.TotalFrequency }}</div>
        <div class="num">{{ info.gre_gp_total }}</div>
      </div>
      <v-chart class="gre-stat-chart" :options="getOptions(gre_gp_total_map)" />
    </div>
    <div class="item">
      <div class="item-text">
        <div class="title">{{ i18n.Home.Updated }}</div>
        <div class="num">{{ info.gre_week_add }}</div>
      </div>
      <v-chart class="gre-stat-chart" :options="getOptions(gre_week_add_map)" />
    </div>
    <div class="item">
      <div class="item-text">
        <div class="title">{{ i18n.Home.SuccessRate }}</div>
        <div class="num">{{ info.gre_hit_rate }}%</div>
      </div>
      <v-chart class="gre-stat-chart" :options="getOptions(gre_hit_rate_map)" />
    </div>
  </Card>
</template>

<script>
import Card from '@/components/card'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'

export default {
  components: {
    Card,
    'v-chart': ECharts
  },
  props: {
    info: Object
  },
  data() {
    return {
      gre_total_map: [1, 2, 3, 4, 3, 5, 4, 5, 5, 7],
      gre_gp_total_map: [3, 5, 5, 5, 3, 4, 3, 5, 6, 8],
      gre_week_add_map: [3, 5, 3, 5, 5, 5, 3, 5, 5, 7],
      gre_hit_rate_map: [2, 3, 4, 5, 4, 5, 5, 5, 4, 7]
    }
  },
  computed: {
    i18n() {
      return this.$t('messages')
    }
  },
  methods: {
    getOptions(list) {
      const mapList = list || []
      return {
        grid: {
          left: 0,
          right: 0
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            data: list,
            type: 'line',
            showSymbol: false,
            smooth: true,
            itemStyle: {
              normal: {
                color: 'rgba(24, 144, 255, 0.8)'
              }
            },
            areaStyle: {
              normal: {
                color: '#d4e9ff'
              }
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.gre-stat {
  width: 300px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  .gre-title {
    display: flex;
    justify-content: space-between;
    h3 {
      margin-bottom: 20px;
      font-size: 18px;
    }
    time {
      color: #9C9C9C;
      font-size: 16px;
    }
  }
  .gre-stat-chart {
    width: 160px;
    height: 40px;
  }
  .item {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 16px;
    position: relative;
    padding-left: 13px;
    &:last-child {
      margin-bottom: 0;
    }
    &:after {
      content: '';
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: #3B86FF;
      position: absolute;
      left: 0px;
      top: 8px;
    }
  }
  .title {
    margin-bottom: 5px;
  }
  .num {
    font-size: 22px;
    font-weight: bold;
    color: #1F1F40;
    span {
      font-size: 14px;
      color: #ff606d;
      i {
        font-size: 12px;
      }
    }
  }
}
</style>
