<template>
  <div class="home-main">
    <div class="home-main-bg" />
    <div class="container">
      <section>
        <UserStat :info="userStatInfo" :allStat="userAllStatInfo" :onChange="fetchUserStat" />
        <Plan
          :liveList="liveList"
          :examDate="userStatSummary.exam_date_format"
          :planInfo="planInfo"
          :planDayList="planDayList"
        />
      </section>
      <section>
        <GreStat :info="greStatInfo" />
        <File :list="fileList" />
      </section>
      <section>
        <Course :statList="courseStatList" />
        <Class :classList="classList" :jobList="jobList" />
      </section>
    </div>
  </div>
</template>

<script>
import {
  apiUserHomeStat,
  apiUserHomeAllStat,
  apiGreHomeStat,
  apiUserMyJobList,
  apiShareFileList,
  apiMyCollect,
  apiUserClassList,
  apiPlanInfo,
  apiPlanDayStat,
  apiMyGoal
} from '@/api/api.js'
import { apiCourseList } from '@/api/course'
import { courseStat } from '@/api/course'
import moment from 'moment'

import UserStat from './components/userStat'
import Plan from './components/plan'
import GreStat from './components/greStat'
import File from './components/file'
import Course from './components/course'
import Class from './components/class'

export default {
  components: {
    UserStat,
    Plan,
    GreStat,
    File,
    Course,
    Class
  },
  data() {
    return {
      userStatInfo: {},
      userAllStatInfo: {},
      userStatSummary: {},
      greStatInfo: {},
      jobList: [],
      collectList: [],
      fileList: [],
      classList: [],
      courseStatList: [],
      planInfo: {},
      planDayList: [],
      liveList: []
    }
  },
  created() {
    this.fetchUserStat()
    this.fetchUserStatSummary()
    this.fetchGreStat()
    this.fetchMyJobList()
    this.fetchMyCollect()
    this.fetchFileList()
    this.fetchClassList()
    this.fetchCourseStat()
    this.fetchPlanInfo()
    this.fetchPlanDayInfo()
    this.fetchLiveList()
    this.fetchUserAllStatus()
  },
  methods: {
    fetchUserStat() {
      apiUserHomeStat().then((res) => {
        if (res.code !== 200) {
          return
        }
        this.userStatInfo = res.data
      })
    },
    async fetchUserStatSummary() {
      const { data } = await apiMyGoal()
      this.userStatSummary = { ...data }
    },
    fetchGreStat() {
      apiGreHomeStat().then((res) => {
        if (res.code !== 200) {
          return
        }
        this.greStatInfo = res.data
      })
    },
    fetchMyJobList() {
      apiUserMyJobList({ page_size: 5 }).then((res) => {
        if (res.code !== 200) {
          return
        }
        this.jobList = res.data.list
      })
    },
    fetchFileList() {
      apiShareFileList({
        page_size: 5,
        lang: this.$i18n.locale === 'ch' ? 1 : 0,
        cate: 0
      }).then((res) => {
        if (res.code !== 200) {
          return
        }
        this.fileList = res.data.list
      })
    },
    async fetchClassList() {
      const { data } = await apiUserClassList()
      this.classList = [].concat(data).splice(0, 3)
    },
    async fetchCourseStat() {
      const { data } = await courseStat()
      this.courseStatList = data
    },
    async fetchPlanInfo() {
      const { data } = await apiPlanInfo()
      this.planInfo = { ...data }
    },
    async fetchPlanDayInfo() {
      const { data } = await apiPlanDayStat({
        day: moment().format('YYYYMMDD')
      })
      this.planDayList = [...data]
    },
    async fetchLiveList() {
      const { data } = await apiCourseList({
        cate_main: 3,
        cate_sub: 31,
        page: 1,
        page_size: 999999
      })
      this.liveList = data.list.filter((m) => m.type === 1)
    },
    async fetchUserAllStatus() {
      const {data} = await apiUserHomeAllStat()
      this.userAllStatInfo = {
        ...data
      }
    },
    fetchMyCollect() {
      apiMyCollect({ pageNum: 3 }).then((res) => {
        if (res.code !== 200) {
          return
        }
        this.collectList = res.data.list
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.home-main {
  position: relative;
  .home-main-bg {
    background-color: #3b86ff;
    height: 66px;
    position: absolute;
    width: 100%;
  }
  .container {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    position: relative;
    section {
      display: flex;
    }
  }
}
</style>
