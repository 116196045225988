<template>
  <Card class="course">
    <div class="title">
      <h1>PTEGO课程</h1>
    </div>

    <ul>
      <li v-for="item of list" :key="item.name">
        <div :style="{backgroundImage:`url(${item.bgImg})`}" @click="handleNavDetail(item)">
          <h2>{{item.name}}</h2>
          <p>{{item.desc}}</p>
          <button>免费试听</button>
        </div>
      </li>
    </ul>
  </Card>
</template>

<script>
  import Card from '@/components/card'

  import bgImg1 from '@/assets/live/ly.png'
  import bgImg2 from '@/assets/live/x.png'
  import bgImg3 from '@/assets/live/s.png'
  import bgImg4 from '@/assets/live/rj.png'

  const getChapterCnt = (list, id) => {
    return ((list || []).find((m) => m.id === id) || {}).chapter_cnt || 0
  }


  export default {
    components: {
      Card
    },
    props: {
      statList: {
        type:Array,
        default: () => []
      }
    },
    computed: {
      list() {
        return [
          {
            name: '口语',
            desc: `共${getChapterCnt(this.statList, 21)}课程`,
            bgImg: bgImg1,
            query: {
              cateMain: 2,
              cateSub: 21
            }
          },
          {
            name: '写作',
            desc: `共${getChapterCnt(this.statList, 22)}课程`,
            bgImg: bgImg2,
            query: {
              cateMain: 2,
              cateSub: 22
            }
          },
          {
            name: '阅读',
            desc: `共${getChapterCnt(this.statList, 23)}课程`,
            bgImg: bgImg3,
            query: {
              cateMain: 2,
              cateSub: 23
            }
          },
          {
            name: '听力',
            desc: `共${getChapterCnt(this.statList, 24)}课程`,
            bgImg: bgImg4,
            query: {
              cateMain: 2,
              cateSub: 24
            }
          },
        ]
      }
    },
    methods: {
      handleNavDetail(item) {
        this.$router.push({
          name: 'course',
          query: {
            ...item.query
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .course {
    width: 300px;
    background-color: #4a4a58;

    .title {
      display: flex;
      justify-content: space-between;
      color: #fff;
      padding-bottom: 10px;

      h1 {
        font-size: 18px;
      }
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        box-sizing: border-box;
        padding: 10px 0;

        &:nth-child(odd) {
          padding-right: 12px;
        }

        &:nth-child(even) {
          padding-left: 12px;
        }

        > div {
          text-align: center;
          background-color: #fff;
          padding: 20px 0 16px;
          border-radius: 10px;
          color: #1F1F40;
          font-size: 14px;
          background-repeat: no-repeat;
          background-size: auto 60%;
          background-position: center 10%;
          transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
          cursor: pointer;
          &:hover{
            transform: translateY(-8px);
          }
          h2 {
            font-size: 18px;
            margin-bottom: 2px;
          }

          button {
            padding: 4px 24px;
            background-color: #F7A437;
            border-radius: 14px;
            font-size: 14px;
            color: #fff;
            border: 0;
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
</style>
