<template>
  <section class="wh_container">
    <div class="wh_content_all">
      <div class="wh_top_changge">
        <h1>{{ title }}</h1>
        <ul>
          <li @click="PreMonth(myDate, false)">
            <div class="wh_jiantou1"></div>
          </li>
          <li class="wh_content_li">{{ dateTop }}</li>
          <li @click="NextMonth(myDate, false)">
            <div class="wh_jiantou2"></div>
          </li>
        </ul>
      </div>
      <div class="wh_content">
        <div class="wh_content_item" v-for="tag in textTop">
          <div class="wh_top_tag">{{ tag }}</div>
        </div>
      </div>
      <div class="wh_content">
        <div
          class="wh_content_item"
          v-for="(item, index) in list"
          @click="clickDay(item, index)"
          :class="[setMarkTime(item, index)]"
        >
          <div
            class="wh_item_date"
            v-bind:class="[
              { wh_isMark: item.isMark },
              { wh_other_dayhide: item.otherMonth !== 'nowMonth' },
              { wh_want_dayhide: item.dayHide },
              { wh_isToday: item.isToday },
              { wh_chose_day: item.chooseDay },
              setClass(item)
            ]"
          >
            <img
              v-if="setPass(item)"
              src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/ca51f935e2f48c3cc9abc443489d68e378ead65d3fd8402cad83360d7b8fdae5.png"
              alt="pass"
            />
            <span>{{ item.id }}</span>
            <i class="point" v-if="setLive(item)" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import timeUtil from './calendar'
import i18n from '@/i18n/i18n'
import moment from 'moment'
import { zeroTime, isValidDate } from '@/utils/time'

const monthEn = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export default {
  data() {
    return {
      myDate: [],
      list: [],
      historyChose: [],
      dateTop: ''
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    markDate: {
      type: Array,
      default: () => []
    },
    markDateMore: {
      type: Array,
      default: () => []
    },
    textTop: {
      type: Array,
      default: () => {
        return ['Sun', 'Mon', 'Tue', 'wed', 'Thu', 'Fri', 'Sat']
      }
    },
    sundayStart: {
      type: Boolean,
      default: () => true
    },
    agoDayHide: {
      type: String,
      default: `0`
    },
    futureDayHide: {
      type: String,
      default: `2554387200`
    },
    markTime: {
      type: Array,
      default: () => []
    },
    examDate: {
      type: String,
      default: ''
    },
    liveList: {
      type: Array,
      default: () => []
    }
  },
  created() {
    this.intStart()
    this.myDate = new Date()
  },
  methods: {
    intStart() {
      timeUtil.sundayStart = this.sundayStart
    },
    setClass(data) {
      let obj = {}
      obj[data.markClassName] = data.markClassName
      return obj
    },
    setMarkTime(data, index) {
      const [startDate, endDate] = this.markTime
      if (!isValidDate(startDate) || !isValidDate(endDate)) {
        return {}
      }

      const nowMo = moment(data.date)
      const now = nowMo.toDate()
      const day = nowMo.day()
      if (now < startDate || now > endDate) {
        return {}
      }

      return {
        'mark-time': true,
        'mark-time-left': day == 0 || now.valueOf() == startDate.valueOf(),
        'mark-time-right': day == 6 || now.valueOf() == endDate.valueOf()
      }
    },
    setPass(item) {
      return moment(item.date).valueOf() === moment(this.examDate).valueOf()
    },
    setLive(item) {
      let isLive = false

      const now = zeroTime(item.date).getTime()
      const list = this.liveList || []
      list.forEach((m) => {
        if (
          now >= zeroTime(m.live_stime).getTime() &&
          now <= zeroTime(m.live_etime).getTime()
        ) {
          isLive = true
        }
      })

      return isLive
    },
    clickDay: function(item, index) {
      if (item.otherMonth === 'nowMonth' && !item.dayHide) {
        this.getList(this.myDate, item.date)
      }
      if (item.otherMonth !== 'nowMonth') {
        item.otherMonth === 'preMonth'
          ? this.PreMonth(item.date)
          : this.NextMonth(item.date)
      }
    },
    ChoseMonth: function(date, isChosedDay = true) {
      date = timeUtil.dateFormat(date)
      this.myDate = new Date(date)
      this.$emit('changeMonth', timeUtil.dateFormat(this.myDate))
      if (isChosedDay) {
        this.getList(this.myDate, date, isChosedDay)
      } else {
        this.getList(this.myDate)
      }
    },
    PreMonth: function(date, isChosedDay = true) {
      date = timeUtil.dateFormat(date)
      this.myDate = timeUtil.getOtherMonth(this.myDate, 'preMonth')
      this.$emit('changeMonth', timeUtil.dateFormat(this.myDate))
      if (isChosedDay) {
        this.getList(this.myDate, date, isChosedDay)
      } else {
        this.getList(this.myDate)
      }
    },
    NextMonth: function(date, isChosedDay = true) {
      date = timeUtil.dateFormat(date)
      this.myDate = timeUtil.getOtherMonth(this.myDate, 'nextMonth')
      this.$emit('changeMonth', timeUtil.dateFormat(this.myDate))
      if (isChosedDay) {
        this.getList(this.myDate, date, isChosedDay)
      } else {
        this.getList(this.myDate)
      }
    },
    forMatArgs: function() {
      let markDate = this.markDate
      let markDateMore = this.markDateMore
      markDate = markDate.map((k) => {
        return timeUtil.dateFormat(k)
      })
      markDateMore = markDateMore.map((k) => {
        k.date = timeUtil.dateFormat(k.date)
        return k
      })
      return [markDate, markDateMore]
    },
    getList: function(date, chooseDay, isChosedDay = true) {
      const [markDate, markDateMore] = this.forMatArgs()
      this.dateTop = `${monthEn[date.getMonth()]} ${date.getFullYear()}`
      // this.dateTop = `${date.getFullYear()}${
      //   i18n.locale === 'ch' ? '年' : ' '
      // } ${
      //   i18n.locale === 'ch'
      //     ? `${date.getMonth() + 1}月`
      //     : monthEn[date.getMonth()]
      // } `
      let arr = timeUtil.getMonthList(this.myDate)
      for (let i = 0; i < arr.length; i++) {
        let markClassName = ''
        let k = arr[i]
        k.chooseDay = false
        const nowTime = k.date
        const t = new Date(nowTime).getTime() / 1000
        //看每一天的class
        for (const c of markDateMore) {
          if (c.date === nowTime) {
            markClassName = c.className || ''
          }
        }
        //标记选中某些天 设置class
        k.markClassName = markClassName
        k.isMark = markDate.indexOf(nowTime) > -1
        //无法选中某天
        k.dayHide = t < this.agoDayHide || t > this.futureDayHide
        if (k.isToday) {
          this.$emit('isToday', nowTime)
        }
        let flag = !k.dayHide && k.otherMonth === 'nowMonth'
        if (chooseDay && chooseDay === nowTime && flag) {
          this.$emit('choseDay', nowTime)
          this.historyChose.push(nowTime)
          k.chooseDay = true
        } else if (
          this.historyChose[this.historyChose.length - 1] === nowTime &&
          !chooseDay &&
          flag
        ) {
          k.chooseDay = true
        }
      }
      this.list = arr
    }
  },
  mounted() {
    this.getList(this.myDate)
  },
  watch: {
    markDate: {
      handler(val, oldVal) {
        this.getList(this.myDate)
      },
      deep: true
    },
    markDateMore: {
      handler(val, oldVal) {
        this.getList(this.myDate)
      },
      deep: true
    },
    agoDayHide: {
      handler(val, oldVal) {
        this.getList(this.myDate)
      },
      deep: true
    },
    futureDayHide: {
      handler(val, oldVal) {
        this.getList(this.myDate)
      },
      deep: true
    },
    sundayStart: {
      handler(val, oldVal) {
        this.intStart()
        this.getList(this.myDate)
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
@media screen and (min-width: 460px) {
  .wh_item_date:hover {
    background: #ffc06a;
    cursor: pointer;
    border-radius: 20px;
  }
}
* {
  margin: 0;
  padding: 0;
}

.wh_container {
  max-width: 410px;
  margin: auto;
}

li {
  list-style-type: none;
}
.wh_top_changge {
  display: flex;
  justify-content: space-between;
  padding: 0 3% 16px;
  > ul {
    display: flex;
  }
  h1 {
    font-size: 18px;
  }
}

.wh_top_changge li {
  cursor: pointer;
  display: flex;
  color: #9c9c9c;
  font-size: 16px;
  font-weight: normal;
  justify-content: center;
  align-items: center;
}

.wh_top_changge .wh_content_li {
  cursor: auto;
  flex: 2.5;
  min-width: 100px;
}
.wh_content_all {
  font-family: -apple-system, BlinkMacSystemFont, 'PingFang SC',
    'Helvetica Neue', STHeiti, 'Microsoft Yahei', Tahoma, Simsun, sans-serif;
  background-color: #fff;
  width: 100%;
  overflow: hidden;
  padding-bottom: 8px;
}

.wh_content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.wh_content:first-child .wh_content_item_tag,
.wh_content:first-child .wh_content_item {
  color: #ddd;
  font-size: 16px;
}

.wh_content_item,
wh_content_item_tag {
  font-size: 15px;
  width: 14.2%;
  text-align: center;
  color: #1f1f40;
  position: relative;
}
.wh_content_item {
  padding: 2px 0;
}

.wh_top_tag {
  width: 90%;
  height: 28px;
  line-height: 28px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #afafaf;
}

.wh_item_date {
  width: 90%;
  height: 28px;
  line-height: 28px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  > img {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .point {
    position: absolute;
    right: 6px;
    top: 11px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #3b86ff;
  }
}

.wh_jiantou1 {
  width: 6px;
  height: 6px;
  border-top: 1px solid #9c9c9c;
  border-left: 1px solid #9c9c9c;
  transform: rotate(-45deg);
}

.wh_jiantou1:active,
.wh_jiantou2:active {
  border-color: #ddd;
}

.wh_jiantou2 {
  width: 6px;
  height: 6px;
  border-top: 1px solid #9c9c9c;
  border-right: 1px solid #9c9c9c;
  transform: rotate(45deg);
}
.wh_content_item > .wh_isMark {
  margin: auto;
  border-radius: 100px;
  background: blue;
  z-index: 2;
}
.wh_content_item .wh_other_dayhide {
  color: #afafaf;
}
.wh_content_item .wh_want_dayhide {
  color: #afafaf;
}
.wh_content_item .wh_isToday {
  background-color: #ffc06a;
  border-radius: 20px;
}
.wh_content_item .wh_chose_day {
  background: #ffc06a;
  border-radius: 100px;
}
.wh_content_item {
  &.mark-time {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 5%;
      left: 0;
      width: 100%;
      height: 90%;
      background-color: #f3f3f7;
    }
    &-left {
      &:after {
        border-bottom-left-radius: 20px;
        border-top-left-radius: 20px;
      }
    }
    &-right {
      &:after {
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
      }
    }
  }
}
</style>
