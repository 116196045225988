<template>
  <Card class="plan">
    <section class="calendar">
      <Calendar
        class="body"
        title="学习计划"
        :markTime="planTime"
        :examDate="examDate"
        :liveList="liveList"
      />
      <footer>
        <ul>
          <li class="point">免费直播课</li>
          <li class="pass">
            <img
              src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/ca51f935e2f48c3cc9abc443489d68e378ead65d3fd8402cad83360d7b8fdae5.png"
              alt=""
            />考试日
          </li>
          <li class="time">学习计划周期</li>
        </ul>
      </footer>
    </section>
    <section class="today">
      <div class="title">
        <h1>今日任务</h1>
        <a @click="handleNavExercises" v-if="planList.length > 0">
          去做题
        </a>
        <a v-else @click="handlePlanTip">立刻定制</a>
      </div>
      <div class="content">
        <ol v-if="planList.length > 0">
          <li v-for="item of planList" :key="item.cate_name">
            <span class="type" :style="{ backgroundColor: item.bgColor }">{{
              item.cate_name
            }}</span>
            <span>完成</span>
            <span class="count">{{ item.finish_cnt }}/{{ item.goal_cnt }}</span>
          </li>
        </ol>
        <div class="no-plan" v-else>
          <p>您当前未制定学习计划</p>
          <img
            src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/8508479db650ac7db0c5b7334fe447550094b37b2157288a7fd89770ec95840e.png"
            alt=""
          />
        </div>
      </div>
      <footer>
        <a @click="handlePlanFullTip"
          >查看完整学习计划 <i class="iconfont icon-Group64"
        /></a>
      </footer>
    </section>

    <PlanTip ref="planTip" />
    <PlanFullTip ref="planFullTip" />
  </Card>
</template>

<script>
import Card from '@/components/card'
import Calendar from '@/components/calendar'
import PlanTip from './planTip'
import PlanFullTip from './planFullTip'
import moment from 'moment'
import { zeroTime } from '@/utils/time'

const TYPE_COLORS = Object.freeze({
  36: '#FF606D',
  37: '#FF606D',
  38: '#FF606D',
  39: '#FF606D',
  40: '#FF606D',
  45: '#FF606D',
  46: '#FF606D',
  28: '#3B86FF',
  29: '#3B86FF',
  30: '#3B86FF',
  31: '#3B86FF',
  32: '#3B86FF',
  33: '#3B86FF',
  34: '#3B86FF',
  35: '#3B86FF',
  42: '#FF9800',
  43: '#FF9800',
  44: '#FF9800',
  52: '#FF9800',
  53: '#FF9800'
})

export default {
  components: {
    Card,
    Calendar,
    PlanTip,
    PlanFullTip
  },
  props: {
    planDayList: {
      type: Array,
      default: () => []
    },
    planInfo: {
      type: Object,
      default: () => ({})
    },
    examDate: {
      type: String,
      default: ''
    },
    liveList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    planList() {
      return this.planDayList
        .filter((m) => m.goal_cnt > 0)
        .map((m) => ({
          ...m,
          bgColor: TYPE_COLORS[m.cate_id]
        }))
    },
    planTime() {
      try {
        const { start_date, goal_day } = this.planInfo
        const startDate = moment(start_date, 'YYYYMMDD')
        return [startDate.toDate(), startDate.add('d', goal_day - 1).toDate()]
      } catch (e) {
        return []
      }
    }
  },
  methods: {
    handleNavExercises() {
      this.$router.push({
        name: 'ExercisesList'
      })
    },
    handlePlanTip() {
      this.$refs.planTip.open()
    },
    handlePlanFullTip() {
      this.$refs.planFullTip.open()
    }
  }
}
</script>

<style scoped lang="scss">
.plan {
  min-height: 300px;
  flex: 1;
  display: flex;
  color: #1f1f40;

  section {
    flex: 1;
  }

  .calendar {
    border-right: 1px solid #d5d5d5;
    padding-right: 20px;
    display: flex;
    flex-direction: column;

    .body {
      flex: 1;
    }

    footer {
      margin-top: 6px;
      display: flex;
      justify-content: right;

      > ul {
        display: flex;
        align-items: center;
        color: #cfcfcf;
        font-size: 12px;

        li {
          margin-left: 10px;

          &.point {
            position: relative;
            &:after {
              content: '';
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background-color: #3b86ff;
              position: absolute;
              left: -8px;
              top: 6px;
            }
          }
          &.pass {
            display: flex;
            align-items: center;
            img {
              width: 15px;
              height: 15px;
              margin-right: 3px;
            }
          }
          &.time {
            padding: 2px 8px;
            border-radius: 50px;
            background-color: #f3f3f7;
          }
        }
      }
    }
  }

  .today {
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    .title {
      display: flex;
      justify-content: space-between;

      h1 {
        font-size: 18px;
      }

      a {
        font-size: 16px;
        color: #3b86ff;
        cursor: pointer;
        text-decoration: none;
      }
    }

    .content {
      flex: 1;

      .no-plan {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 220px;
        margin-top: 20px;
        > p {
          color: #c9cfe4;
          font-size: 16px;
          margin-bottom: 14px;
        }
        > img {
          width: 124px;
        }
      }

      ol {
        display: flex;
        flex-wrap: wrap;
        margin-top: 12px;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 220px;

        li {
          width: 50%;
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          font-size: 16px;
          line-height: 16px;
          color: #9c9c9c;

          .type {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 24px;
            border-radius: 4px;
            font-size: 14px;
            background-color: #ffdfe2;
            color: #fff;
            margin-right: 8px;
          }

          .count {
            margin-left: 8px;
            color: #1f1f40;
          }
        }
      }
    }

    footer {
      display: flex;
      flex-direction: row-reverse;
      margin-top: 10px;
      font-size: 16px;

      a {
        cursor: pointer;
        text-decoration: none;
        color: #9c9c9c;

        i {
          position: relative;
          display: inline-block;
          transform: rotateZ(90deg);
        }
      }
    }
  }
}
</style>
