<template>
  <el-dialog
          class="radio-dialog"
          :visible.sync="visible"
          width="600px"
          :append-to-body="true"
  >
    <section class="title">
      <h1>查看完整学习计划</h1>
      <p>复习周期、整体规划、完成度一览无遗</p>
    </section>
    <section class="info">
      <img
              src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/c75a6f6e542d0fefc987867277a0921065a816b1c7c7851c6ed8e4c65cc88b97.png"
              alt="">
      <div>
        <h2>完整学习计划还包括...</h2>
        <ul>
          <li>纵观整体计划完成度，更具全局观</li>
          <li>收集“打卡”印章，增强成就感</li>
          <li>一键生成打卡合集图片，分享收获</li>
        </ul>
        <p>
          <img :src="require('@/assets/temporary/download.png')" alt="">
          <span>扫码下载PTEGO APP立刻查看</span>
        </p>
      </div>
    </section>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        visible: false
      }
    },
    methods: {
      open() {
        this.visible = true
      }
    }
  }
</script>

<style scoped lang="scss">
  .radio-dialog {
    ::v-deep .el-dialog {
      border-radius: 10px;
      overflow: hidden;
    }

    ::v-deep .el-dialog__header {
      padding: 0;
    }

    ::v-deep .el-dialog__body {
      padding: 0 !important;
    }

    ::v-deep .el-icon-close {
      color: #9dc2ff;
      font-size: 22px;
      font-weight: bold;
    }

    .title {
      background-color: #3b86ff;
      text-align: center;
      padding: 40px 0 25px;
      color: #9dc2ff;
      font-size: 14px;

      h1 {
        color: #fff;
        font-size: 32px;
        font-weight: normal;
      }
    }

    .info {
      display: flex;
      padding: 34px;
      color: #1F1F40;

      > img {
        width: 256px;
        margin-right: 20px;
      }

      h2 {
        font-size: 18px;
      }

      ul {
        margin-top: 2px;

        li {
          margin-top: 8px;
          padding-left: 10px;
          position: relative;

          &:after {
            content: '';
            width: 5px;
            height: 5px;
            background-color: #3B86FF;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 8px;
          }
        }
      }

      p {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 95px;
          margin-top: 32px;
        }

        span {
          text-align: center;
          margin-top: 10px;
          font-size: 12px;
          color: #8e8e9f;
        }
      }
    }
  }
</style>
