<template>
  <Card class="class">
    <section class="list" :class="{'no-split': !hasClass}">
      <div class="title">
        <h1>我的班级</h1>
        <a @click="handleNavClass" v-if="hasClass">全部 <i class="iconfont icon-Group64"/> </a>
      </div>

      <ul v-if="hasClass">
        <li v-for="item of classList" :key="item.id">
          <section>
            <h2>{{item.class_name}}</h2>
            <p><img src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/379597b17383f304f8501972d0e3f8e5f6d4cdf34a878209357e4988d7ab427f.png" alt="">{{item.teacher}}</p>
          </section>
          <section>
            <div class="over" v-if="item.is_over == 1">已结课</div>
            <div v-else>进行中</div>
          </section>
        </li>
      </ul>
      <div v-else class="no-class-data">
        <p>
          您当前未加入班级 <a >咨询课程</a>
        </p>
        <img src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/043a145d64973fdac20a343bf60e2a7c52ebd2164beca3a8584b5ca9291fa07c.png" alt="">
      </div>
    </section>
    <section class="work" v-if="hasClass">
      <div class="title">
        <h1>作业列表</h1>
        <a @click="handleNavWork" >全部 <i class="iconfont icon-Group64"/> </a>
      </div>

      <ul v-if="hasJob">
        <li v-for="item of jobList" :key="item.id">
          <h2>{{item.title}}</h2>
          <div v-if="item.is_new_flag == 1">
            <span>NEW</span>
          </div>
          <p>
            <span v-if="item.is_finsh == 0">待完成</span>
            <i v-else class="iconfont icon-finish"></i>
          </p>
        </li>
      </ul>
      <div v-else class="no-class-data">
        <p>
          您的班级暂未布置作业，请随时关注
        </p>
        <img src="https://ptegofeifan.oss-accelerate.aliyuncs.com/go/043a145d64973fdac20a343bf60e2a7c52ebd2164beca3a8584b5ca9291fa07c.png" alt="">
      </div>
    </section>
  </Card>
</template>

<script>
  import Card from '@/components/card'

  export default {
    components: {
      Card
    },
    props: {
      classList: {
        type: Array,
        default: () => []
      },
      jobList: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      hasClass() {
        return this.classList.length > 0
      },
      hasJob() {
        return this.jobList.length > 0
      }
    },
    methods: {
      handleNavWork() {
        this.$router.push({
          name: 'ExerciseBook'
        })
      },
      handleNavClass() {
        this.$router.push({
          name: 'exerciseClass'
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .class {
    flex: 1;
    background-color: #4a4a58;
    display: flex;

    section {
      flex: 1;
    }

    .title {
      display: flex;
      justify-content: space-between;
      color: #fff;

      h1 {
        font-size: 18px;
      }

      a {
        font-size: 16px;
        color: #fff;
        cursor: pointer;
        text-decoration: none;

        i {
          display: inline-block;
          transform: rotateZ(90deg);
        }
      }
    }

    .list {
      border-right: 1px solid #D5D5D5;
      padding-right: 30px;
      &.no-split {
        border-right: 0;
      }

      ul {
        padding-top: 12px;

        li {
          padding: 14px 16px;
          background-color: #fff;
          border-radius: 10px;
          font-size: 14px;
          margin: 8px 0;
          display: flex;

          > section {
            flex: 1;

            &:last-child {
              flex: none;
            }
          }

          h2 {
            color: #1F1F40;
            font-size: 16px;
            font-weight: normal;
          }

          p {
            color: #CFCFCF;
            max-width: 200px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-top: 10px;
            display: flex;
            align-items: center;
            img {
              height: 14px;
              margin-right: 4px;
            }
          }
          div {
            background-color: #ffdfe2;
            padding: 2px 8px;
            border-radius: 12px;
            color: #FF606D;
            font-size: 14px;
            margin-bottom: 4px;
            &.over {
              background-color: #d8f3ed;
              color: #3EC2A3;
            }
          }
        }
      }
    }

    .work {
      padding-left: 30px;
      ul {
        margin-top: 20px;
        li {
          height: 50px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #81818b;
          &:first-child {
            border-top: 1px solid #81818b;
          }
          h2 {
            flex:1;
            width: 0;
            font-size: 16px;
            font-weight: normal;
            color: #ffffff;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          div {
            span {
              background-color: #ffd2dd;
              padding: 4px 8px;
              font-size: 14px;
              color: #FF606D;
              border-radius: 12px;
            }
          }
          p {
            width: 70px;
            text-align: center;
            color: #a5a5ac;
            font-size: 16px;
            i {
              font-size: 18px;
              color: #3ec2a3;
            }
          }
        }
      }
    }

    .no-class-data {
      text-align: center;
      padding-top: 24px;
      p {
        font-size: 16px;
        color: #a5a5ac;
        > a {
          margin-left: 12px;
          color: #3B86FF;
          cursor: pointer;
        }
      }
      img {
        width: 112px;
        margin-top: 30px;
      }
    }
  }
</style>
