<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
  export default {}
</script>

<style scoped lang="scss">
  .card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px 30px;
    margin-bottom: 40px;
    & + & {
      margin-left: 50px;
    }
  }
</style>
