<template>
  <el-dialog
    class="setting-dialog"
    :title="$t('Home.setting')"
    :visible.sync="localVisible"
    width="432px"
    :before-close="handleClose"
    :append-to-body="true"
  >
    <div v-loading="loading">
      <el-form :model="info" :rules="rules" ref="form">
        <div class="ipt-group">
          <div class="input-header">
            <label>{{ $t('Home.objectives') }}</label>
            <div class="set-socre" v-if="showsocreInfo">
              <span
                v-for="(item, index) of 3"
                :key="index"
                @click="setSocre(index + 6)"
              >
                {{ index + 6 }}{{ $t('Home.zha') }}
              </span>
            </div>
          </div>
          <div class="input-contnet">
            <el-form-item prop="goal_score_avg">
              <el-input
                :placeholder="$t('Home.divide')"
                v-model="info.goal_score_avg"
                class="input socre-input number-input"
                type="number"
              />
            </el-form-item>
            <div class="socre-list" v-if="showsocreInfo">
              <el-form-item prop="goal_score_speak">
                <el-input
                  :placeholder="$t('Home.speak')"
                  v-model="info.goal_score_speak"
                  class="input number-input"
                  type="number"
                />
              </el-form-item>
              <el-form-item prop="goal_score_write">
                <el-input
                  :placeholder="$t('Home.write')"
                  v-model="info.goal_score_write"
                  class="input number-input"
                  type="number"
                />
              </el-form-item>
              <el-form-item prop="goal_score_read">
                <el-input
                  :placeholder="$t('Home.read')"
                  v-model="info.goal_score_read"
                  class="input number-input"
                  type="number"
                />
              </el-form-item>
              <el-form-item prop="goal_score_listen">
                <el-input
                  :placeholder="$t('Home.listen')"
                  v-model="info.goal_score_listen"
                  class="input number-input"
                  type="number"
                />
              </el-form-item>
            </div>
            <span class="add-btn" v-else @click="showsocreInfo = true">{{
              $t('Home.addScore')
            }}</span>
          </div>
        </div>
        <div class="ipt-group">
          <div class="input-header">
            <label>{{ $t('Home.examDate') }}</label>
          </div>
          <div>
            <el-date-picker
              class="input"
              v-model="info.exam_date"
              type="date"
              :placeholder="$t('Home.choiceDate')"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="ipt-group">
          <div class="input-header">
            <label>{{ $t('Home.oldScore') }}</label>
            <div class="set-socre" v-if="showHistoryInfo">
              <span
                v-for="(item, index) of 3"
                :key="index"
                @click="setHistory(index + 6)"
              >
                {{ index + 6 }}{{ $t('Home.zha') }}
              </span>
            </div>
          </div>
          <div class="input-contnet">
            <el-form-item prop="exam_last_score_avg">
              <el-input
                :placeholder="$t('Home.divide')"
                v-model="info.exam_last_score_avg"
                class="input socre-input number-input"
                type="number"
              />
            </el-form-item>
            <div class="socre-list" v-if="showHistoryInfo">
              <el-form-item prop="exam_last_score_speak">
                <el-input
                  :placeholder="$t('Home.speak')"
                  v-model="info.exam_last_score_speak"
                  class="input number-input"
                  type="number"
                />
              </el-form-item>
              <el-form-item prop="exam_last_score_write">
                <el-input
                  :placeholder="$t('Home.write')"
                  v-model="info.exam_last_score_write"
                  class="input number-input"
                  type="number"
                />
              </el-form-item>
              <el-form-item prop="exam_last_score_read">
                <el-input
                  :placeholder="$t('Home.read')"
                  v-model="info.exam_last_score_read"
                  class="input number-input"
                  type="number"
                />
              </el-form-item>
              <el-form-item prop="exam_last_score_listen">
                <el-input
                  :placeholder="$t('Home.listen')"
                  v-model="info.exam_last_score_listen"
                  class="input number-input"
                  type="number"
                />
              </el-form-item>
            </div>
            <span class="add-btn" v-else @click="showHistoryInfo = true">{{
              $t('Home.addScore')
            }}</span>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        size="mini"
        @click="submit"
        :loading="loading"
        >{{ $t('Home.sure') }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { apiSetMyGoal, apiMyGoal } from '@/api/api.js'

const SCORE_TYPE = {
  6: 50,
  7: 65,
  8: 79
}

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback()
      }
      if (value < 10 || value > 90) {
        callback(new Error(this.$t('Home.scoreRange')))
      } else if (String(value).includes('.')) {
        callback(new Error(this.$t('Home.noDigit')))
      } else {
        callback()
      }
    }
    return {
      showDialog: true,
      localVisible: this.visible,
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      info: {
        exam_date: '',
        goal_score_avg: '',
        goal_score_read: '',
        goal_score_write: '',
        goal_score_speak: '',
        goal_score_listen: '',
        exam_last_score_avg: '',
        exam_last_score_read: '',
        exam_last_score_write: '',
        exam_last_score_speak: '',
        exam_last_score_listen: ''
      },
      showsocreInfo: false,
      showHistoryInfo: false,
      loading: false,
      rules: {
        goal_score_avg: [{ validator: validatePass, trigger: 'blur' }],
        goal_score_read: [{ validator: validatePass, trigger: 'blur' }],
        goal_score_write: [{ validator: validatePass, trigger: 'blur' }],
        goal_score_speak: [{ validator: validatePass, trigger: 'blur' }],
        goal_score_listen: [{ validator: validatePass, trigger: 'blur' }],
        exam_last_score_avg: [{ validator: validatePass, trigger: 'blur' }],
        exam_last_score_read: [{ validator: validatePass, trigger: 'blur' }],
        exam_last_score_write: [{ validator: validatePass, trigger: 'blur' }],
        exam_last_score_speak: [{ validator: validatePass, trigger: 'blur' }],
        exam_last_score_listen: [{ validator: validatePass, trigger: 'blur' }]
      }
    }
  },
  watch: {
    showDialog(val) {
      this.localVisible = val
    }
  },
  created() {
    this.fetchMyGoal()
  },
  methods: {
    handleClose() {
      this.$emit('closeDialog')
    },
    fetchMyGoal() {
      this.loading = true
      apiMyGoal()
        .then((res) => {
          if (res.code !== 200) {
            return
          }

          const {
            exam_date_format,
            goal_score_avg,
            goal_score_read,
            goal_score_write,
            goal_score_speak,
            goal_score_listen,
            exam_last_score_avg,
            exam_last_score_read,
            exam_last_score_write,
            exam_last_score_speak,
            exam_last_score_listen
          } = res.data
          this.info = {
            exam_date: exam_date_format,
            goal_score_avg: goal_score_avg || '',
            goal_score_read: goal_score_read || '',
            goal_score_write: goal_score_write || '',
            goal_score_speak: goal_score_speak || '',
            goal_score_listen: goal_score_listen || '',
            exam_last_score_avg: exam_last_score_avg || '',
            exam_last_score_read: exam_last_score_read || '',
            exam_last_score_write: exam_last_score_write || '',
            exam_last_score_speak: exam_last_score_speak || '',
            exam_last_score_listen: exam_last_score_listen || ''
          }
          if (
            goal_score_read ||
            goal_score_write ||
            goal_score_speak ||
            goal_score_listen
          ) {
            this.showsocreInfo = true
          }
          if (
            exam_last_score_read ||
            exam_last_score_speak ||
            exam_last_score_write ||
            exam_last_score_listen
          ) {
            this.showHistoryInfo = true
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    setSocre(socre) {
      const addSocre = SCORE_TYPE[socre]
      this.info = {
        ...this.info,
        goal_score_avg: addSocre,
        goal_score_read: addSocre,
        goal_score_write: addSocre,
        goal_score_speak: addSocre,
        goal_score_listen: addSocre
      }
    },
    setHistory(socre) {
      const addSocre = SCORE_TYPE[socre]
      this.info = {
        ...this.info,
        exam_last_score_avg: addSocre,
        exam_last_score_read: addSocre,
        exam_last_score_write: addSocre,
        exam_last_score_speak: addSocre,
        exam_last_score_listen: addSocre
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          apiSetMyGoal({
            ...this.info
          }).then((res) => {
            if (res.code !== 200) {
              return
            }
            this.$emit('successDialog')
            this.handleClose()
            this.$layer.msg(
              "<div class='success-icon'></div><div>" +
                this.$t('Home.settingSuccess') +
                '</div>'
            )
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss">
.setting-dialog {
  padding: 0 16px;
  .el-dialog__header {
    display: flex;
    margin: 0 16px;
    justify-content: space-between;
    padding: 9px 0;
    border-bottom: 1px solid #ebebf2;
    align-items: center;
    .el-dialog__headerbtn {
      position: inherit;
    }
    .el-dialog__title {
      font-size: 14px;
      font-weight: bold;
      color: #a4afb7;
    }
  }
  .el-button--primary {
    background: #3b86ff;
    border-color: #3b86ff;
    border-radius: 5px;
    padding: 4px 18px;
  }
  .input-contnet {
    display: flex;
    align-items: center;
  }
  .socre-list {
    justify-content: space-between;
    display: flex;
    margin-left: 8px;
    flex: 1;
    .input {
      width: 60px;
    }
  }
  .ipt-group {
    padding-top: 20px;
    &:first-child {
      padding-top: 0;
    }
    .input-header {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
    }
    .set-socre {
      margin-left: auto;
      span {
        margin-left: 17px;
        color: #3b86ff;
        cursor: pointer;
      }
    }
    > label {
      display: inline-block;
      font-size: 14px;
    }
    .number-input {
      .el-input__inner {
        padding: 0 0 0 15px;
      }
    }
    .input {
      &.error {
        .el-input__inner {
          border: 1px solid #ff606d;
        }
      }
      .el-input-group__prepend {
        background-color: #fff;
      }
      .el-input__inner:hover {
        border-color: #3b86ff;
      }
      .el-input__inner:focus {
        border-color: #3b86ff;
        box-shadow: 0px 0px 0px 2px #bae7ff;
      }
    }
    .socre-input {
      width: 120px;
    }
  }
  .add-btn {
    margin-left: 15px;
    color: #3b86ff;
    cursor: pointer;
  }
}
</style>
