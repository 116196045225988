import moment from 'moment'

export const zeroTime = (time, format) => {
  return moment(time, format)
    .hour(0)
    .minute(0)
    .second(0)
    .toDate()
}

export const isValidDate = (date) => {
  return date instanceof Date && !isNaN(date.getTime())
}
