<template>
  <el-dialog
          class="stat-dialog"
          title="学习数据"
          :visible.sync="visible"
          width="402px"
          :append-to-body="true"
  >
    <ul class="stat-dialog-body">
      <li v-for="item of list" :key="item.name">
        <p>
          <b>{{item.value}}</b>
          {{item.unit}}
        </p>
        <div class="line"/>
        <h2>{{item.name}}</h2>
      </li>
    </ul>
  </el-dialog>
</template>

<script>

  const statLabels = [
    {
      name: '今日已练',
      field: 'try_today_count',
      value: 0,
      unit: '题'
    },
    {
      name: '学习天数',
      field: 'try_day_count',
      value: 0,
      unit: '天'
    },
    {
      name: '练习题目',
      field: 'gre_try_count',
      value: 0,
      unit: '题'
    },
    {
      name: '获赞数',
      field: 'try_like_count',
      value: 0,
      unit: '个'
    },
    {
      name: '题目收藏',
      field: 'gre_like_cnt',
      value: 0,
      unit: '题'
    },
    {
      name: '单词收藏',
      field: 'word_like_cnt',
      value: 0,
      unit: '个'
    },
    {
      name: '目标分数',
      field: 'goal_score',
      value: 0,
      unit: '分'
    },
    {
      name: '加入班级',
      field: 'class_join_cnt',
      value: 0,
      unit: '个'
    }
  ]

  export default {
    props: {
      info: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        visible: false
      }
    },
    computed: {
      list() {
        return statLabels.map(item => ({
          ...item,
          value: this.info[item.field] || 0
        }))
      }
    },
    methods: {
      open() {
        this.visible = true
      },
    }
  }
</script>

<style lang="scss" scoped>
  .stat-dialog {
    ::v-deep .el-dialog {
      border-radius: 10px;
    }
    ::v-deep .el-dialog__header {
      text-align: left;
    }
    ::v-deep .el-dialog__body {
      padding-top: 4px !important;
    }
  }

  .stat-dialog-body {
    display: flex;
    flex-wrap: wrap;

    > li {
      width: 25%;
      text-align: center;
      color: #222D3D;
      margin: 15px 0;

      p {
        font-size: 12px;

        b {
          font-size: 26px;
        }
      }

      .line {
        position: relative;
        height: 2px;
        margin: 4px 0;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 18px;
          height: 2px;
          border-radius: 2px;
          background-color: #FFC06A;
        }
      }

      h2 {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
</style>
